import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { Client } from '@savgroup-front-common/types/src/Billing/Client';

export const ENDPOINT = `${APIConfiguration.owner}clients`;

interface GetClientsdQueryArgs {
  tenantId: string;
  term?: string;
  page?: number;
  pageSize?: number;
  orderColumn?:
    | 'None'
    | 'Name'
    | 'Email'
    | 'CustomerType'
    | 'PaymentType'
    | 'BillingMode'
    | 'PaymentTermDays'
    | 'PreferredLanguage';
}

const getClientsdQuery = async ({
  tenantId,
  term,
  orderColumn = 'None',
  page = 1,
  pageSize = 10,
}: GetClientsdQueryArgs): Promise<
  BackResponse<Client[]> | BackResponseFailure
> => {
  try {
    const url = buildUrl(ENDPOINT, {
      tenantId,
      term,
      orderColumn,
      page,
      pageSize,
    });

    const response = await apiCall<BackResponse<Client[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getClientsdQuery;
